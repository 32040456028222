import { useInView } from 'react-intersection-observer'

import { EventsBar } from '../../components/events-bar/events-bar'
import { MainMenu } from '../main-menu/main-menu'
import { Button } from '../../components/button/button'

import LogoSVGinverse from './img/ideea-logo-inverse.svg'
import CSS from './header.module.css'
import { Main } from 'next/document'

const otherEvents = [
    {
        id: 7,
        title: 'AHIF',
        url: 'https://www.ahif.com',
        target: true
    },
    {
        id: 5,
        title: 'AviaDev',
        url: 'https://www.aviationdevelop.com',
        target: true
    },
    {
        id: 1,
        title: 'FHS World',
        url: 'https://www.futurehospitality.com/ae',
        target: false
    },
    {
        id: 2,
        title: 'FHS Saudi Arabia',
        url: 'https://www.futurehospitality.com/sa',
        target: false
    },
    {
        id: 3,
        title: 'GRIF Saudi Arabia',
        url: 'https://www.futurehospitality.com/sa/grif',
        target: false
    },
    {
        id: 6,
        title: 'SAHIC',
        url: 'https://www.sahic.com',
        target: true
    }
]

export const Header = ({ mainMenu }) => {

    const [menuTrigger, inView, entry] = useInView({
		threshold: 0.1,
	})

    const [hideEventsBarTrigger, headerInView, header] = useInView({
		threshold: 0,
        initialInView: true
	})

    return (
        <>
            <span ref={menuTrigger}>
                <EventsBar 
                    hidden={ !headerInView ? true : false }
                    thisEvent={'IDEEA'} 
                    events={otherEvents} 
                    theme={{
                        background: 'var(--dark)',
                        text: 'white',
                        accent: 'var(--secondary)'
                    }}
                />
            </span>

            <header 
                ref={hideEventsBarTrigger}
                className={`
                    ${CSS.header} 
                    bg-primary
                    ${ !inView && CSS.fixed }
                `}
            >
                
                <div className='flex gap-sm'>
                    <MainMenu mainMenu={mainMenu} otherEvents={otherEvents} />
                        
                    <div className={CSS.brandContainer}>
                        <a href={`/`} aria-label='IDEEA Home'>
                            <LogoSVGinverse className={CSS.brand}/>
                        </a>
                        
                        <p className={CSS.venue}>
                            <span className='fw-600 fs-5'>Hospitality Investment Forum</span><br/>
                            18-20 September, 2023<br/> Prague Marriott Hotel, Prague, Czech Republic
                        </p>
                    </div>

                </div>

                <p className='fw-600'><Button href="https://www.linkedin.com/company/ideeaforum" inverse target={`_blank`}>Follow us on LinkedIn</Button></p>
                {/* <p className='fw-600'><Button href="/register" inverse>Purchase Pass</Button></p> */}
            
            </header>
        </>
    )
}


export const HeaderShim = () => <div className={CSS.shim}></div>