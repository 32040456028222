import Link from 'next/link'
import Image from 'next/image'

import HubspotForm from '../../components/hubspot-form/hubspot-form'
import { ButtonSocial } from  '../../components/button-social/button-social'

import CSS from './footer.module.css'

export const Footer = ({ socialConnections, contactInfo, hubspotForm }) => {

    return (
        <footer className="footer bg-default">
            <section className="block">
                <div className={`${CSS.container} container formatted`}>

                        {
                            hubspotForm &&
                            <section className={CSS.section}>
                                <p className='fs-3 fw-600 caps c-secondary'>Never miss an announcement</p>
                                <p>If you haven&apos;t already registered your interest, submit your name and email, and we&apos;ll keep you updated on all the exciting announcements we have in store for IDEEA taking place in Prague on 18-20 September 2023.</p>
                                <HubspotForm portalId={hubspotForm.portalId} formId={hubspotForm.formId} />
                            </section>
                        }
            
                        <section className={`${CSS.section} ${CSS.divider}`}>

                            <p className='fs-3 fw-600 caps c-secondary'>Join the conversation</p>
                            <p className='fs-5 caps fw-700 c-secondary'>#IDEEA</p>
                            <p className='flex flex-wrap gap-xxs'>
                                {
                                    socialConnections ?
                                    socialConnections.map( connection => {
                                        return (
                                            <ButtonSocial 
                                                key={connection.id} 
                                                icon={connection.icon} 
                                                url={connection.hyperlink} 
                                                alt={connection.label}
                                                decal={'bg-primary'}
                                            />
                                        )
                                    })
                                    : null
                                }
                            </p>

                            {
                                contactInfo &&
                                <div className="mt-md formatted pt-xs bt-1 bc-grey-20">
                                    {/* <h5>Contact us</h5> */}
                                    <div dangerouslySetInnerHTML={{__html: contactInfo }} />
                                </div>
                            }


                            <div className='mt-md pt-xs bt-1 bc-grey-20'>
                                <p className='fs-5 caps fw-700 c-secondary'>Organised by</p>
                                <div className="mt-sm flex flex-wrap gap-sm">
                                    <p>
                                        <a className={CSS.partnerLogo} href="https://www.thebench.com" target='_blank' rel='noreferrer' aria-label="Organiser">
                                            <BenchLogo />
                                        </a>
                                    </p>
                                </div>
                            </div>

                            
                        </section>

                    

                </div>

            </section>

            <section className="block bg-primary">
                <div className={`${CSS.menu} container center caps fw-600`}>
                    <p><Link href='/'><a className='uLink'>Home</a></Link></p>
                    <p><Link href='https://www.thebench.com/privacy'><a className='uLink' target='_blank'>Privacy Policy</a></Link></p>
                    <p><Link href='/purchase-pass'><a className='uLink'>Purchase Pass</a></Link></p>
                    <p><Link href='/sponsor-ideea'><a className='uLink'>Become a Sponsor</a></Link></p>
                    <p><Link href='/contact-us'><a className='uLink'>Contact us</a></Link></p>
                </div>
                    
                <div className={`${CSS.legals} container`}>
                    <p><small>
                        © {new Date().getFullYear()} The Bench
                    </small></p>
                </div>
            </section>

        </footer>
    )
}



const BenchLogo = ({ fill }) => {

    return (
        <svg width="100%" height="100%" viewBox="0 0 211 88" style={{ fillRule:`evenodd`, clipRule:`evenodd`, strokeLinejoin:`round`, strokeMiterlimit:2 }}>
            <g transform="matrix(1,0,0,1,-50,-43)">
                <g transform="matrix(1.67232,0,0,1.67232,-54.9698,-434.75)">
                    <g id="bench-logo">
                        <g transform="matrix(1,0,0,1,134.043,325.883)">
                            <path d="M0,0.388C-0.005,-4.024 -3.574,-7.592 -7.988,-7.6C-12.396,-7.592 -15.966,-4.024 -15.975,0.388L-15.975,11.704L-19.69,11.704L-19.69,0.388C-19.69,-6.077 -14.451,-11.314 -7.988,-11.316C-1.522,-11.314 3.716,-6.077 3.716,0.388L3.716,11.704L0.004,11.704L0,0.388Z" style={{ fill:`rgb(122,15,175)`, fillRule:`nonzero` }}/>
                        </g>
                        <g transform="matrix(1,0,0,1,100.652,334.255)">
                            <path d="M0,-15.973C3.77,-15.967 6.916,-13.355 7.76,-9.843L-7.759,-9.843C-6.917,-13.355 -3.769,-15.967 0,-15.973M-7.759,-6.129L11.555,-6.129C11.652,-6.734 11.702,-7.353 11.702,-7.985C11.7,-14.45 6.463,-19.687 0,-19.689C-6.462,-19.687 -11.702,-14.45 -11.705,-7.985C-11.702,-1.523 -6.462,3.714 0,3.716C4.449,3.714 8.313,1.237 10.295,-2.415L5.708,-2.415C4.26,-0.928 2.241,-0.003 0,0.001C-2.24,-0.003 -4.262,-0.928 -5.711,-2.415L-5.741,-2.415L-5.741,-2.448C-6.716,-3.455 -7.42,-4.722 -7.759,-6.129Z" style={{ fill:`rgb(122,15,175)`, fillRule:`nonzero` }}/>
                        </g>
                        <g transform="matrix(1,0,0,1,75.1309,311.076)">
                            <path d="M0,23.18C-4.411,23.172 -7.979,19.604 -7.988,15.195L-7.988,15.184C-7.975,10.779 -4.407,7.214 0,7.206C4.411,7.214 7.981,10.783 7.987,15.195C7.981,19.604 4.411,23.172 0,23.18M0,3.491C-3.088,3.491 -5.897,4.689 -7.988,6.644L-7.988,-3.716L-11.703,-3.716L-11.703,15.195C-11.702,21.657 -6.464,26.894 0,26.896C6.464,26.894 11.7,21.657 11.705,15.195C11.7,8.73 6.464,3.493 0,3.491" style={{ fill:`rgb(122,15,175)`, fillRule:`nonzero` }}/>
                        </g>
                        <g transform="matrix(1,0,0,1,188.849,307.36)">
                            <path d="M0,30.227L0,18.911C-0.002,12.446 -5.239,7.206 -11.702,7.206C-14.792,7.206 -17.599,8.405 -19.69,10.358L-19.69,0L-23.405,0L-23.405,30.227L-19.69,30.227L-19.69,18.888C-19.668,14.487 -16.108,10.93 -11.702,10.922C-7.292,10.93 -3.724,14.497 -3.714,18.911L-3.714,30.227L0,30.227Z" style={{ fill:`rgb(122,15,175)`, fillRule:`nonzero` }}/>
                        </g>
                        <g transform="matrix(1,0,0,1,159.078,323.446)">
                            <path d="M0,5.647C-1.145,8.657 -4.026,10.803 -7.438,10.81C-11.847,10.802 -15.418,7.234 -15.424,2.825C-15.418,-1.587 -11.847,-5.156 -7.438,-5.164C-4.026,-5.158 -1.145,-3.01 0,-0.002L3.919,-0.002C2.654,-5.102 -1.95,-8.877 -7.438,-8.879C-13.902,-8.877 -19.139,-3.64 -19.142,2.825C-19.139,9.287 -13.902,14.524 -7.438,14.526C-1.95,14.524 2.654,10.747 3.919,5.647L0,5.647Z" style={{ fill:`rgb(122,15,175)`, fillRule:`nonzero` }}/>
                        </g>
                        <g transform="matrix(1,0,0,1,91.0659,300.177)">
                            <path d="M0,-8C1.888,-7.997 3.463,-6.689 3.886,-4.93L-3.886,-4.93C-3.465,-6.689 -1.888,-7.997 0,-8M-3.886,-3.07L5.787,-3.07C5.835,-3.373 5.86,-3.683 5.86,-4C5.859,-7.237 3.236,-9.86 0,-9.861C-3.237,-9.86 -5.861,-7.237 -5.862,-4C-5.861,-0.763 -3.237,1.86 0,1.861C2.228,1.86 4.163,0.619 5.156,-1.21L2.859,-1.21C2.133,-0.465 1.122,-0.002 0,0C-1.122,-0.002 -2.135,-0.465 -2.861,-1.21L-2.875,-1.21L-2.875,-1.227C-3.364,-1.731 -3.716,-2.365 -3.886,-3.07Z" style={{ fill:`rgb(122,15,175)`, fillRule:`nonzero` }}/>
                        </g>
                        <g transform="matrix(1,0,0,1,84.1841,285.768)">
                            <path d="M0,16.415L0,10.269C-0.001,6.758 -2.845,3.914 -6.355,3.914C-8.033,3.914 -9.558,4.564 -10.693,5.625L-10.693,0L-12.71,0L-12.71,16.415L-10.693,16.415L-10.693,10.257C-10.681,7.867 -8.748,5.936 -6.355,5.931C-3.96,5.936 -2.022,7.873 -2.017,10.269L-2.017,16.415L0,16.415Z" style={{ fill:`rgb(122,15,175)`, fillRule:`nonzero` }}/>
                        </g>
                        <g transform="matrix(1,0,0,1,67.5811,302.124)">
                            <path d="M0,-16.297L0,-11.738L2.879,-11.738L2.879,-9.855L0,-9.855L0,0L-1.929,0L-1.929,-9.855L-2.457,-9.855L-4.721,-9.822L-4.721,-11.738L-1.929,-11.738L-1.929,-16.297L0,-16.297Z" style={{ fill:`rgb(122,15,175)`, fillRule:`nonzero` }}/>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}